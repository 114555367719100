import React, { useEffect } from "react"
import Layout from "../../components/layout"
import TheFATable from "../../components/thefa-table"

export default function TeamBoysU13Juniors() {

    
  return ( 
    <Layout>
    <section className="container">
    <h1>Under 14's - Juniors</h1>
    <div className="row mb-2">
        <div className="col-md-8">
            <div className="row">
            <img src="/boys-u14-juniors.jpg" className="img-fluid mb-4 team-page-hero" />
            </div>
            <div className="row mt-4 mb-4">
            <p>
            A committed group of players and coaches enjoying their 7th season in the Garforth league. We aim to play an attractive brand of football which is built from the back and have developed the boys so they can adapt to any number of positions they are asked to play in.
            </p>
            <p>
                All coaches are FA qualified. In additional all staff are CRC with at least one First Aid qualified member present at all times.
            </p>
            </div>

            <div className="row border thefa-table g-0 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 ">
            <h3>League Table</h3>
            <TheFATable leagueId="954916576"></TheFATable>
            </div>

        </div>

        <div className="col-md-4">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div className="col p-4 d-flex flex-column position-static">
                <h3 className="lcj-color">Team Information</h3>
                <dl>
                <dt>Age Range</dt>
                <dd>School Year 9, DoB 01/09/2008 - 31/08/2009</dd>
                <dt>Manager(s)</dt>
                <dd>Pete Walsh & Mike Inman</dd>
                <dt>Training</dt>
                <dd>Bedquilts Saturday 8:45am till 10:15am<br/>
                Wednesday Evenings</dd>
                <dt>League</dt>
                <dd>Garforth Junior League - Division 2</dd>
                <dt>Join the team</dt>
                <dd><a href="mailto:leedscityjnrs@gmail.com">leedscityjnrs@gmail.com</a></dd>
                </dl>
            </div>
            </div>

            <div className="row">
            <div className="col">
                <div className="card shadow-sm">
                <div className="card-header">Team Sponsor</div>
                <img src="/sponsor-icongroup.jpg" />
                <div className="card-body">
                    <p className="card-text">A social value partner to industry, particularly the construction industry. Experts in designing, developing & delivering CSR projects, changing lives & futures.</p>
                    <div className="btn-group">
                    <a href="https://www.icongroup.org.uk/home" target="_blank" className="btn btn-sm btn-outline-secondary">View Sponsor</a>
                    </div>
                </div>
                </div>
            </div>    
            </div>
        </div>
    </div>
    </section>
    </Layout>
  );
}

